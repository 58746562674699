h2 {
    margin-top: 60px;
    font-size: 1.8em;
    color: #1f272e;
}

h1 {
    font-size: 3em;
    font-weight: 800;
    color: #000;
}

p {
    font-size: 1.3rem;
}

li {
    font-size: 1.3rem;
}