.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.css-13vtz3x-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.font-title {
  font-family: 'inter';
  font-size: 64px;
  line-height: 69px;
  letter-spacing: 0px;
  color: #517DFF;
  font-weight: 800;
  text-align: center;
  padding: 30px 0px;
}

.font-headline {
  font-family: 'inter';
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0px;
  color: #000;
  font-weight: 600;
  padding: 30px 20px;
}

.font-label {
  font-family: 'inter';
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #000;
  font-weight: 400;
  padding: 15px 20px;
}

.font-body {
  font-family: 'inter';
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #000;
  font-weight: 400;
  padding: 10px 20px;
}

.font-bf {
  font-weight: 800;
}

ol {
  padding-left: 20px;
}

li {
  padding-left: 20px;
}

ul {
  padding-left: 20px;
}

.nav-link {
  color: #000;
  font-family: SF Pro;
  font-size: 18.04px;
  font-weight: 500;
  line-height: 27.05px;
  text-align: left;
  text-decoration: none;
  display: block;
  /* margin: 10px 0; */
  padding: 10px;
  padding-left: 15px;
  border-radius: 10px;
  /* border-left: 5px solid #E4E4E4; */
  position: relative;
}

.nav-link::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #E4E4E4;
  position: absolute;
  left: -7.5px;
  top: calc(50% - 7.5px);
}

.nav-link::before {
  content: "";
  display: block;
  width: 3px;
  height: 100%;
  background-color: #E4E4E4;
  position: absolute;
  left: -1.5px;
  top: 0px;
}

.nav-link:first-child::before {
  top: 50%;
  height: 50%;
}

.nav-link:last-child::before {
  height: 50%;
}

.nav-link:hover {
  color: rgb(7, 121, 255);
  background-color: rgba(81, 125, 255, 0.13);
}

.nav-link.active {
  color: #517DFF;
}

.nav-link.active::after {
  background-color: #517DFF;
}

.css-9y6hsl-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.task-name-font {
  color: #000;
  font-family: Inter;
  font-size: 25.833px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.task-small-font {
  color: #9C9C9C;
  font-family: Inter;
  font-size: 19.554px;
  font-style: normal;
  font-weight: 500;
  line-height: 129.639%;
  /* 25.349px */
}